<template>
  <div class="contain">
    <div class="header-title">
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <el-form
        ref="ruleForm"
        style="width: 80%"
        :model="form"
        :label-width="labelWidth"
      >
        <div class="form-title">
          <!-- <i class="el-icon-caret-bottom" style="margin-right:0.52083vw"></i> -->
          <span>基本信息</span>
        </div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="分公司：">{{ form.orgName }}</el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态：">
              <span v-if="form.status">{{ form.statusName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="undefined">
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  (((form.status != '3' &&
                    form.status != '8' &&
                    form.status != '9' &&
                    jumpType == 'headquarters') ||
                    ((form.status == '1' ||
                      form.status == '4' ||
                      form.status == '5' ||
                      form.status == '6') &&
                      jumpType == 'company')) &&
                    isButtonEdit) ||
                  ((form.status == '1' ||
                    form.status == '4' ||
                    form.status == '5' ||
                    form.status == '6') &&
                    jumpType == 'school' &&
                    iSuperviseEdit)
                "
                @click="handleTaskViewFun('supervise')"
                :disabled="form.isSupervise === '1'"
                >{{ form.isSupervise === "1" ? "已督办" : "督办" }}</el-button
              >
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  (form.status == '6' || form.status == '7') &&
                  jumpType == 'headquarters' &&
                  isButtonEdit
                "
                @click="handleTaskViewFun('refreshTime')"
                >延长时间</el-button
              >
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  ((form.status == '5' && jumpType == 'headquarters') ||
                    (form.status == '4' && jumpType == 'company')) &&
                  isButtonEdit
                "
                @click="handleTaskViewFun('withdraw')"
                >撤回</el-button
              >
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  (((form.status === '3' ||
                    form.status === '8' ||
                    form.status === '9') &&
                    jumpType == 'headquarters') ||
                    ((form.status === '3' ||
                      form.status === '8' ||
                      form.status === '9') &&
                      jumpType == 'company') ||
                    ((form.status === '3' ||
                      form.status === '8' ||
                      form.status === '9') &&
                      jumpType == 'school')) &&
                  isButtonEdit
                "
                @click="handleTaskViewFun('viewAllEvaluate')"
                >查看总评</el-button
              >
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  (((form.status == '3' || form.status == '8') &&
                    jumpType == 'headquarters') ||
                    ((form.status == '2' || form.status == '7') &&
                      jumpType == 'company') ||
                    ((form.status == '1' ||
                      form.status == '4' ||
                      form.status == '5' ||
                      form.status == '6') &&
                      jumpType == 'school')) &&
                  isButtonEdit
                "
                @click="handleTaskViewFun('viewScore')"
                >评分</el-button
              >
              <el-button
                class="banliBtn"
                type="text"
                v-if="
                  ((form.status == '9' && jumpType == 'headquarters') ||
                    (form.status == '9' && jumpType == 'company')) &&
                  isButtonEdit
                "
                @click="handleTaskViewFun('share')"
                >分享</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="园校："> {{ form.parkName }}</el-form-item>
        <el-form-item label="岗位："> {{ form.jobName }}</el-form-item>
        <el-form-item label="督导主题：">
          {{ form.supervisionTopic }}</el-form-item
        >
        <el-form-item label="督导方式：">{{
          form.supervisionMethodName
        }}</el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="总题目：">{{
              form.totalQuestions
            }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="总分数：">
              <span>{{ form.totalScore }}</span></el-form-item
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="发布时间：">{{ form.sendTime }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="截止日期：">
              <span>{{ form.parkDeadline }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="dashed-line"></div>
        <el-form-item label="督办记录：" class="duban">
          <el-table :data="superviseRecord" style="">
            <el-table-column
              prop="userName"
              label="督办人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="督办时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="suggestion" label="督办建议" align="center">
              <template slot-scope="scope">
                <span>{{
                  scope.row.suggestion ? scope.row.suggestion : "无"
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div class="dashed-line"></div>
        <el-form-item label="评分记录：">
          <el-table class="table" :data="operationRecord">
            <el-table-column
              prop="endTime"
              label="时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="nodeName" label="操作" align="center">
              <template slot-scope="scope">
                <span>{{
                  scope.row.nodeName === "1"
                    ? "园校评分"
                    : scope.row.nodeName === "2"
                    ? "分公司评分"
                    : scope.row.nodeName === "3"
                    ? "总部评分"
                    : ""
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="score"
              label="分数"
              align="center"
            ></el-table-column>
            <el-table-column
                prop="option"
                label="操作"
                align="center"
            >
              <template slot-scope="scope">
                <el-button
                    class="table-handle"
                    @click="handleTaskFun('scoreDetail', scope.row)"
                    type="text"
                >
                  {{
                    scope.row.nodeName === "1"
                        ? "查看园校评分"
                        : scope.row.nodeName === "2"
                            ? "查看分公司评分"
                            : scope.row.nodeName === "3"
                                ? "查看总部评分"
                                : ""
                  }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <supervise-dialog
      ref="superviseDialog"
      @returnFun="superviseReturnFun"
      :superviseDialogVisible="superviseDialogVisible"
    ></supervise-dialog>
    <share-dialog
      ref="shareDialog"
      @returnFun="shareReturnFun"
      :shareDialogVisible="shareDialogVisible"
      :shareType="shareType"
    ></share-dialog>
    <refresh-time-dialog
      ref="refreshTimeDialog"
      @returnFun="refreshTimeReturnFun"
      :refreshTimeDialogVisible="refreshTimeDialogVisible"
    ></refresh-time-dialog>
  </div>
</template>
<script>
import SuperviseDialog from "./components/superviseDialog.vue";
import ShareDialog from "./components/shareDialog.vue";
import RefreshTimeDialog from "./components/refreshTimeDialog.vue";
import { mapState } from "vuex";
export default {
  name: "taskViewDetail",
  components: { SuperviseDialog, ShareDialog, RefreshTimeDialog },
  data() {
    return {
      pageTitle: "查看", //标题
      jumpType: "", //跳转页面的类型
      id: null, //待办任务id
      form: {
        orgName: "",
        status: "",
        statusName: "",
        parkName: "",
        jobId: "",
        jobName: "",
        supervisionTopic: "",
        supervisionMethod: "",
        supervisionMethodName: "",
        totalQuestions: "",
        totalScore: "",
        sendTime: "",
        parkDeadline: "",
      }, //表单
      superviseRecord: [], //督办记录
      operationRecord: [], //操作记录
      flowStatusHeaderOptions: [
        {
          name: "园校待办",
          value: "1",
        },
        {
          name: "分公司待办",
          value: "2",
        },
        {
          name: "总部待办",
          value: "3",
        },
        {
          name: "分公司驳回",
          value: "4",
        },
        {
          name: "驳回园校",
          value: "5",
        },
        {
          name: "园校逾期",
          value: "6",
        },
        {
          name: "分公司逾期",
          value: "7",
        },
        {
          name: "总部待评分",
          value: "8",
        },
        {
          name: "已完成",
          value: "9",
        },
      ], //总部状态下拉
      flowStatusCompanyOptions: [
        {
          name: "园校待办",
          value: "1",
        },
        {
          name: "待办",
          value: "2",
        },
        {
          name: "总部审批",
          value: "3",
        },
        {
          name: "驳回园校",
          value: "4",
        },
        {
          name: "总部驳回",
          value: "5",
        },
        {
          name: "园校逾期",
          value: "6",
        },
        {
          name: "逾期",
          value: "7",
        },
        {
          name: "总部待评分",
          value: "8",
        },
        {
          name: "已完成",
          value: "9",
        },
      ], //分公司状态下拉
      flowStatusSchoolOptions: [
        {
          name: "待办",
          value: "1",
        },
        {
          name: "分公司审批中",
          value: "2",
        },
        {
          name: "总部审批中",
          value: "3",
        },
        {
          name: "分公司驳回",
          value: "4",
        },
        {
          name: "总部驳回",
          value: "5",
        },
        {
          name: "逾期",
          value: "6",
        },
        {
          name: "分公司逾期",
          value: "7",
        },
        {
          name: "总部待评分",
          value: "8",
        },
        {
          name: "已完成",
          value: "9",
        },
      ], //园校状态下拉
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉

      superviseDialogVisible: false, //督办弹窗
      superviseDialogType: "", //督办类型
      taskId: null, //任务id

      shareDialogVisible: false, //分享标识
      shareType: "", //分享类型

      refreshTimeDialogVisible: false, //延长时间标识

      isButtonEdit: false, //是否可以编辑
      iSuperviseEdit: false, //园校督办是否可以

      userInfo: {}, //登录用户信息

      labelWidth: "120px",
      dudaoTaskId:'',
    };
  },
  computed: {
    ...mapState({
      postOptions: (state) => state.common.postList,
    }),
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;

    this.jumpType = this.$route.query.jumpType;
    this.id = this.$route.query.id;
    setTimeout(() => {
      this.getForm();
    }, 300);
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  methods: {
    //取消
    onCancel() {
      this.$router.back(-1);
    },
    //获取是否可以编辑的权限
    getEditAuth(form) {
      if (
        ((form.groupAudit.indexOf(this.userInfo.userId) != -1 ||
          form.publishUser == this.userInfo.userId) &&
          this.jumpType == "headquarters") ||
        (form.branchAudit.indexOf(this.userInfo.userId) != -1 &&
          this.jumpType == "company") ||
        (form.receiver.indexOf(this.userInfo.userId) != -1 &&
          this.jumpType == "school")
      ) {
        this.isButtonEdit = true;
      } else {
        this.isButtonEdit = false;
      }
      if (
        this.userInfo.postLabelList.indexOf("aFAwR0ZpbywrIEnyff6Iw==") != -1 &&
        form.receiver.indexOf(this.userInfo.userId) == -1 &&
        this.jumpType == "school"
      ) {
        this.iSuperviseEdit = true;
      } else {
        this.iSuperviseEdit = false;
      }
    },
    //获取查看信息
    getForm() {
      this.$api
        .getAgencyTaskInfo(this.id, {
          level:
            this.jumpType == "headquarters"
              ? "1"
              : this.jumpType == "company"
              ? "2"
              : "3",
        })
        .then((res) => {
          if (res.data.code == 0) {
            let resultInfo = res.data.data;
            let form = {
              orgName: resultInfo.orgName,
              status: resultInfo.status,
              parkName: resultInfo.parkName,
              jobId: resultInfo.jobId,
              jobName: resultInfo.jobName,
              supervisionTopic: resultInfo.supervisionTopic,
              supervisionMethod: resultInfo.supervisionMethod,
              totalQuestions: resultInfo.totalQuestions,
              totalScore: resultInfo.totalScore,
              sendTime: resultInfo.sendTime,
              parkDeadline: resultInfo.parkDeadline,
              groupAudit: resultInfo.groupAudit || "",
              branchAudit: resultInfo.branchAudit || "",
              receiver: resultInfo.receiver || "",
              isSupervise: resultInfo.isSupervise || "",
              publishUser: resultInfo.publishUser || "",
            };

            let statusName = "";
            let statusOptions =
              this.jumpType == "school"
                ? this.flowStatusSchoolOptions
                : this.jumpType == "company"
                ? this.flowStatusCompanyOptions
                : this.flowStatusHeaderOptions;
            let statusIndex = statusOptions.findIndex((statusInfo) => {
              return statusInfo.value == form.status;
            });
            if (statusIndex != -1) {
              statusName = statusOptions[statusIndex].name;
            }
            form.statusName = statusName;

            // let jobName = ""
            // let jobIndex = this.postOptions.findIndex((jobInfo) => { return jobInfo.value == form.jobId })
            // if(jobIndex != -1){
            //   jobName = this.postOptions[jobIndex].name
            // }
            // form.jobName = jobName

            let supervisionMethodName = "";
            let supervisionMethodIndex = this.superviseTypeOptions.findIndex(
              (supervisionMethodInfo) => {
                return supervisionMethodInfo.value == form.supervisionMethod;
              }
            );
            if (supervisionMethodIndex != -1) {
              supervisionMethodName =
                this.superviseTypeOptions[supervisionMethodIndex].name;
            }
            form.supervisionMethodName = supervisionMethodName;

            this.form = { ...form }; //基本信息

            this.getEditAuth(form);

            let superviseRecordList = resultInfo.superviseRecordsList || [];
            this.superviseRecord = superviseRecordList; //督办记录

            let operationRecordList = resultInfo.supOrTaskHistoryList || [];
            this.operationRecord = operationRecordList; //操作记录
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //督办返回事件
    superviseReturnFun(data) {
      if (data.flag) {
        let suggestion = data.suggestion;
        let params = {
          orderId: this.id,
          level:
            this.jumpType == "headquarters"
              ? "1"
              : this.jumpType == "company"
              ? "2"
              : "3",
          suggestion: suggestion,
          taskId:this.$route.query.taskId
        };
        this.$api
          .addSupervise(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "督办成功!",
                duration: 2000,
                onClose: () => {
                  this.superviseDialogVisible = false;
                  this.$nextTick(() => {
                    this.superviseDialogType = "";
                    this.getForm();
                  });
                },
              });
            } else {
              this.$refs.superviseDialog.superviseButtonloading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$refs.superviseDialog.superviseButtonloading = false;
          });
      } else {
        this.superviseDialogVisible = false;
        this.$nextTick(() => {
          this.superviseDialogType = "";
        });
      }
    },
    //分享返回事件
    shareReturnFun(data) {
      if (data.flag) {
        let personList = data.personList;
        this.shareDialogVisible = false;
        this.$nextTick(() => {
          this.shareType = "";
          this.getForm();
        });
      } else {
        this.shareDialogVisible = false;
        this.$nextTick(() => {
          this.shareType = "";
        });
      }
    },
    //延长时间返回事件
    refreshTimeReturnFun(data) {
      if (data.flag) {
        let endDate = data.endDate;
        let params = {
          orderId: this.id,
          parkDeadline: endDate,
        };
        this.$api
          .extendTaskDeadLine(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "延长时间成功!",
                duration: 2000,
                onClose: () => {
                  this.refreshTimeDialogVisible = false;
                  this.$nextTick(() => {
                    this.getForm();
                  });
                },
              });
            } else {
              this.$refs.refreshTimeDialog.refreshTimeButtonloading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$refs.refreshTimeDialog.refreshTimeButtonloading = false;
          });
      } else {
        this.refreshTimeDialogVisible = false;
      }
    },
    //处理事件
    handleTaskViewFun(type) {
      switch (type) {
        case "refreshTime":
          this.refreshTimeDialogVisible = true;
          break;
        case "withdraw":
          this.$api
            .withdrawAgencyTask(this.id)
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "撤回成功!",
                  duration: 2000,
                  onClose: () => {
                    this.getForm();
                  },
                });
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {});
          break;
        case "supervise":
          this.superviseDialogVisible = true;
          this.superviseDialogType = "single";
          break;
        case "viewAllEvaluate":
          this.$router.push({
            path: "/agencyTaskTotalScoreDetail",
            query: { id: this.id, jumpType: this.jumpType },
          });
          break;
        case "viewScore":
          this.$router.push({
            path: "/agencyTaskScoreDetail",
            query: { id: this.id, jumpType: this.jumpType },
          });
          break;
        case "share":
          this.shareDialogVisible = true;
          this.$nextTick(() => {
            this.shareType = this.jumpType;
          });
          break;
        default:
          break;
      }
    },
    /** 行内-编辑 */
    handleTaskFun(type, row) {
      console.log(row);
      switch (type) {
        case "scoreDetail":
          let jumpType = row.nodeName ==="1"?"school": row.nodeName ==="2"?"company":"headquarters";
          this.$router.push({
            path: "/agencyTaskScoreDetail",
            query: { id: row.orderId, jumpType: jumpType,isEdit: false,nodeName:row.nodeName},
          });
          break;
        default:
          break;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>
<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;
    .elInput {
      width: 100%;
    }
    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active {
      color: #010334;
    }
    /deep/.el-switch__label {
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label {
      color: #010334 !important;
    }
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
    border-radius: 0 !important;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  .table-handle.file {
    display: block;
  }
  /deep/.el-table {
    thead tr {
      background-color: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      :first-child {
        border-radius: 12px 0px 0px 0px;
      }
      :nth-last-child(2) {
        border-radius: 0px 12px 0px 0px;
      }
    }
    th {
      background-color: #f1f4f9;
      padding: 0;
    }
  }
}
</style>